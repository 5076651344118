<template>
  <!-- Preview Manager View -->
  <div class="main-okrreview">
    <b-row>
      <b-col sm="12" class="active-history">
        <div class="okr-list okr-status custom-progress my-3 okr-preview">
          <div class="d-flex align-items-center justify-content-between mb-1 top-header" v-if="name">
            <b-link class="text-dark h3 d-block obj-header mb-0" :to="{
              name: 'review-okrs'
            }">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <g clip-path="url(#clip0_8998_6476)">
                  <path d="M5 12H19" stroke="#667085" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M5 12L11 18" stroke="#667085" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path d="M5 12L11 6" stroke="#667085" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </g>
                <defs>
                  <clipPath id="clip0_8998_6476">
                    <rect width="24" height="24" fill="#667085" />
                  </clipPath>
                </defs>
              </svg> {{ name }} OKR {{quarter}} {{year}}</b-link>
              <!-- v-if="objectives.length && GetHistories().length" -->
              <div class="right-filter d-flex custom-dropdown">
                <b-button v-if="objectives.length" :variant="(commentsOpened == false) ? 'secondary' :'primary'" pill class="cmts-history p-0" @click="commentsOpened = !commentsOpened">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                    <g clip-path="url(#clip0_10714_37718)">
                      <path d="M6 6.75H12" stroke="#667085" stroke-width="1.35" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M6 9.75H10.5" stroke="#667085" stroke-width="1.35" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M6.75 13.5H4.5C3.90326 13.5 3.33097 13.2629 2.90901 12.841C2.48705 12.419 2.25 11.8467 2.25 11.25V5.25C2.25 4.65326 2.48705 4.08097 2.90901 3.65901C3.33097 3.23705 3.90326 3 4.5 3H13.5C14.0967 3 14.669 3.23705 15.091 3.65901C15.5129 4.08097 15.75 4.65326 15.75 5.25V11.25C15.75 11.8467 15.5129 12.419 15.091 12.841C14.669 13.2629 14.0967 13.5 13.5 13.5H11.25L9 15.75L6.75 13.5Z" stroke="#667085" stroke-width="1.35" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_10714_37718">
                        <rect width="18" height="18" fill="#667085"/>
                      </clipPath>
                    </defs>
                  </svg>
                </b-button>
                <b-button variant="outline-secondary" pill v-if="GetHistories().length" v-b-modal.edithistory-pop
                  class="history-btn p-0 d-flex align-items-center justify-content-center">
                  <!-- <b-button variant="outline-secondary" pill  v-b-modal.edithistory-pop
                  class="history-btn p-0 d-flex align-items-center justify-content-center"> -->
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <g clip-path="url(#clip0_10712_37110)">
                      <path d="M8 5.33203V7.9987L9.33333 9.33203" stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M2.03516 7.33338C2.18455 5.86675 2.86867 4.50645 3.95698 3.51202C5.04529 2.51759 6.46162 1.95864 7.93574 1.9418C9.40986 1.92496 10.8386 2.45142 11.9493 3.42074C13.0601 4.39005 13.7751 5.73436 13.9579 7.1972C14.1408 8.66003 13.7787 10.139 12.9407 11.3519C12.1028 12.5648 10.8476 13.4267 9.41468 13.7733C7.98177 14.1198 6.47143 13.9267 5.17182 13.2308C3.87221 12.5348 2.8743 11.3848 2.36849 10M2.03516 13.3334V10H5.36849" stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_10712_37110">
                        <rect width="16" height="16" fill="#667085"/>
                      </clipPath>
                    </defs>
                  </svg>
                </b-button>
              </div>
          </div>
          <ul class="m-0 p-0 cmt-okr" v-if="objectives.length">
            <li class="list-unstyled mt-75" v-for="objective in objectives" :class="(commentsOpened == false) ? 'before-viewcmd' :'after-viewcmd'">
              <b-card class="shadow-none mb-0 left-data">
                <b-row>
                  <b-col sm="12" md="8" class="d-flex align-items-center"  v-b-toggle="'KeyResultsView-' + objective.hashid">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 7 14" fill="none"
                      class="click-icon">
                      <path
                        d="M6.66489 7.85465L1.26596 13.7558C1.19149 13.8372 1.11082 13.8983 1.02394 13.939C0.937057 13.9797 0.843972 14 0.744681 14C0.546099 14 0.37234 13.9254 0.223404 13.7762C0.0744681 13.6269 -2.49053e-08 13.4302 -3.5579e-08 13.186L-5.7638e-07 0.813953C-5.87054e-07 0.569767 0.0744675 0.373062 0.223404 0.223837C0.37234 0.0746116 0.546099 -2.38708e-08 0.74468 -3.2551e-08C0.794326 -3.47211e-08 0.968085 0.0813951 1.26596 0.244185L6.66489 6.14535C6.78901 6.28101 6.87589 6.41667 6.92553 6.55232C6.97518 6.68798 7 6.83721 7 7C7 7.16279 6.97518 7.31202 6.92553 7.44767C6.87589 7.58333 6.78901 7.71899 6.66489 7.85465Z"
                        fill="#6E6B7B" />
                    </svg>
                    <h4 class="mb-0 pb-0 ml-50">{{ objective.title }}</h4>
                  </b-col>
                  <b-col sm="12" md="4" class="d-flex align-items-center justify-content-end">
                    <b-badge :variant="getStatusVariant(objective.status)"> {{ objective.status }}</b-badge>
                  </b-col>
                </b-row>
                 <!--OKR Comments -->
                 <!-- <div class="cmt-icon">
                    <b-img :src="require('@/assets/images/icons/okr/comment.svg')" class="mx-auto light-theme"/>
                  </div> -->
                  <b-collapse :id="'KeyResultsView-' + objective.hashid" @shown="commentTarget = objective.hashid+'-comment'" @hidden="commentTarget=null;" >
                    <ul class="m-0 p-0 tree h-100 nested-okr review-treeview">
                      <li class="list-unstyled nested-table">
                        <ul class="m-0 pl-3 sub-tree">
                          <li class="list-unstyled mt-1 border-0" v-for="key_result,key in objective.key_results">
                            <div class="shadow-none rounded mb-0">
                              <b-row class="align-items-center">
                                <b-col sm="8" md="8" class="pl-0">
                                  <label class="mb-0">{{ key_result.result_description }}</label>
                                </b-col>
                                <b-col sm="4" md="4" class="justify-content-end d-flex">
                                  <div class="input-value">
                                    <p class="mb-0" v-if="key_result.metric_type == 'Boolean'">{{ (key_result.state &&
                                      key_result.state == 'Yes') ? 'Completed' : 'Incomplete' }}</p>
                                    <p class="mb-0" v-if="key_result.metric_type == 'Number'">{{ key_result.progress + " / "+key_result.goal}}</p>
                                    <p class="mb-0" v-if="key_result.metric_type == 'Percentage'">{{ key_result.progress +
                                      " / " + key_result.goal + "%" }}</p>
                                  </div>
                                </b-col>
                              </b-row>
                            </div>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </b-collapse>
              </b-card>
              <!-- Comment History -->
              <div class="cmt-main">
                  <div class="enter-cmt new-cmt" v-if="objective.comments_count == 0 && commentTarget == objective.hashid+'-comment'">
                    <b-form-group class="mb-0">
                      <b-form-input placeholder="Add Comment" @keyup.enter="addOrUpdateComment(objective.hashid,$event)"/>
                    </b-form-group>
                  </div>
                  <div v-if="objective.comments_count != 0 && commentsOpened" class="cmt-view" >
                    <ul class="m-0 p-0">
                      <li class="cmt-list list-unstyled" v-for="cmd in objective.comments" >
                        <div class="d-flex align-items-center justify-content-between">
                          <p class="mb-0"><span class="user-detail">{{(cmd.comment_owner_id == userData.hashid) ? 'You' : cmd.comment_owner_name}}</span> <span class="date">{{getDate(cmd.time)}}</span> <span class="time">{{getDateTime(cmd.time)}}</span></p>
                          <p class="m-0 completed-tick">
                            <feather-icon class="cursor-pointer" icon="CheckIcon" v-if="cmd.mark_as_done == true"/>
                            <b-dropdown class="blog-dropdown" variant="link" size="sm" toggle-class="text-decoration-none p-0"
                              no-caret>
                              <template v-slot:button-content>
                                <feather-icon icon="MoreVerticalIcon" size="16"
                                  class="text-body align-middle mr-25 text-muted" v-b-tooltip.hover.v-default
                                  title="More Options" />
                              </template>
                              <b-dropdown-item v-if="cmd.comment_owner_id != userData.hashid && cmd.mark_as_done == false"  class="btn-sm p-0 border-0" @click="markAsResolved(objective.hashid,cmd,'resolved')">
                                <feather-icon icon="CheckIcon" size="13" class="mr-50" />
                                <span class="">Mark as resolved</span>
                              </b-dropdown-item>
                              <b-dropdown-item v-if="cmd.comment_owner_id != userData.hashid && cmd.mark_as_done == true"  class="btn-sm p-0 border-0" @click="markAsResolved(objective.hashid,cmd,'un-resolved')">
                                <feather-icon icon="XIcon" size="13" class="mr-50" />
                                <span class="">Mark as un-resolved</span>
                              </b-dropdown-item>
                              <b-dropdown-item  v-if="cmd.comment_owner_id == userData.hashid"  class="btn-sm p-0 border-0"  v-b-toggle="cmd.comment_hash_id">
                                <feather-icon icon="EditIcon" size="13" class="mr-50" />
                                <span class="">Edit</span>
                              </b-dropdown-item>
                              <b-dropdown-item v-if="cmd.comment_owner_id == userData.hashid" class="btn-sm p-0 border-0" @click="confirmDeleteCommand(objective.hashid,cmd)">
                                <feather-icon icon="TrashIcon" size="13" class="mr-50" />
                                <span class="">Delete</span>
                              </b-dropdown-item>
                            </b-dropdown>
                          </p>
                        </div>
                        
                        <p class="cmt-text m-0">{{cmd.comment}}</p>
                        <b-collapse :id="cmd.comment_hash_id">
                          <b-row>
                            <b-col md="12" class="mt-1">
                              <div class="enter-cmt" >
                                <b-form-group class="mb-0">
                                  <b-form-input placeholder="Add Comment" :value="cmd.comment" @keyup.enter="addOrUpdateComment(objective.hashid,$event,cmd.comment_hash_id)"/>
                                </b-form-group>
                              </div>
                            </b-col>
                          </b-row>
                        </b-collapse>

                      </li>
                      <b-row>
                        <b-col md="12" class="mt-1">
                          <div class="enter-cmt" >
                            <b-form-group class="mb-0">
                              <b-form-input  @keyup.enter="addOrUpdateComment(objective.hashid,$event)"/>
                            </b-form-group>
                          </div>
                        </b-col>
                      </b-row>
                    </ul>
                    <!-- <b-link class="cmt-expand">View Conversation</b-link> -->
                  </div>
              </div>
              <!-- End Comment History -->
            </li>
          </ul>
          <div class="text-center my-2 empty-data" v-else>
            <b-img :src="require('@/assets/images/icons/okr/EmptyReview.svg')" class="mx-auto light-theme" />
            <b-img :src="require('@/assets/images/icons/okr/emptyreviewdark.svg')" class="mx-auto dark-theme" />
            <h4> No OKR to Review </h4>
            <p class="d-block mt-75 mb-1 pb-25">All user's OKRs are Approved and Published</p>
            <b-button variant="primary" :to="{
              name: 'review-okrs'
            }" pill> Back to List </b-button>
          </div>
          <div v-if="objectives.length && objectives[0].manager_id == userData.hashid"
            class="d-flex align-items-center justify-content-end my-1 managerview-btn">
            <b-button variant="outline-secondary" @click="notifyNeedImprovement" pill>Need Improvements</b-button>
            <b-button variant="primary" @click="approveAll" pill>Approve & Publish</b-button>
          </div>
        </div>
        <b-modal id="edithistory-pop" size="md" title="Edit History" hide-footer class="custom-popmodal edit-history"
          right header-class="sticky-top">
          <template #modal-header="{ close }">
            <h5 class="header mb-0">Edit History</h5>
            <feather-icon class="cursor-pointer floating-close" icon="XIcon" size="16" @click="closeHistory()" />
          </template>
          <div class="edit-content shadow-none rounded-bottom">
            <ul class="timeline-history m-0 p-0" v-for="history in GetHistories()" >
              <li class="timeline__event"  v-if="history.old && history.new">
                <div class="timeline__event__user">
                  <b-img :src="history.user_avatar" class="mx-auto light-theme" />
                </div>
                <div class="timeline__event__content">
                  <div class="timeline__event__description">
                    <p class="mb-0">{{history.user_name}} {{history.action_type}} <span class="modify-text">“{{history.subject}}” from
                        <span class="value"><small>{{history.old}} </small></span> to <span class="value"><small>
                            {{history.new}}</small></span></span></p>
                    <div class="d-flex algin-items-center edit-date mt-25">
                      <span>{{history.time}}</span>
                    </div>
                  </div>
                </div>
              </li>
              <li class="timeline__event" v-else>
                <div class="timeline__event__user">
                  <b-img :src="history.user_avatar" class="mx-auto light-theme" />
                </div>
                <div class="timeline__event__content">
                  <div class="timeline__event__description">
                    <p class="mb-0">{{history.user_name}} {{history.action_type}} <span class="modify-text">“{{history.subject}}”</span></p>
                    <div class="d-flex algin-items-center edit-date mt-25">
                      <span>{{history.time}}</span>
                    </div>
                  </div>
                </div>
              </li>
              
            </ul>
          </div>
        </b-modal>
      </b-col>
    </b-row>
  </div>
  <!-- End Preview Manager View -->
</template>

<script>
import $ from 'jquery';
import { $themeConfig } from '@themeConfig';
import { FormWizard, TabContent } from "vue-form-wizard";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import vSelect from "vue-select";
import axios from "@axios";
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormRadio,
  BForm,
  BInputGroup,
  BInputGroupPrepend,
  BTabs,
  BTab,
  BListGroup,
  BListGroupItem,
  VBTooltip,
} from "bootstrap-vue";
import { required, email, numeric } from "@validations";
import flatPickr from "vue-flatpickr-component";
import { onUnmounted } from "@vue/composition-api";
import store from "@/store";

export default {
  components: {
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCard,
    BCardHeader,
    BCardTitle,
    BCol,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BFormCheckbox,
    BForm,
    BTabs,
    BTab,
  },
  
  directives: {
    "b-tooltip": VBTooltip,
  },
  created() {

    this.user_id = this.$route.params.id;
    this.year = this.$route.params.year;
    this.quarter = this.$route.params.quarter;

    this.getUserOKR();
  },
  data() {
    return {
      user_id: '',
      year: '',
      quarter: '',
      objectives: [],
      name: '',
      userData: this.$cookies.get("userData"),
      commentsOpened:false,
      commentTarget:'',
      newCommentText:''
    };

  },
  mounted() {
    $(document).ready(function () {
      $(".cmts-history").click(function () {
        $(".cmt-okr").toggleClass("active-cmt");
      });
    });
  },
  methods: {

    confirmDeleteCommand(objective_id,comment){
      this.$swal({
        title: "Are you sure?",
        text: "You are about to delete this comment '"+comment.comment+"'",
        showCancelButton: true,
        confirmButtonText: "Yes",
        customClass: {
          confirmButton: "btn yes-btn",
          cancelButton: "btn btn-outline-secondary ml-1 cancel-btn",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
            this.deleteComment(objective_id,comment);
        }
      });
    },
    deleteComment(objective_id,comment){
      axios.post("objective/deleteComment",{objective_id:objective_id,comment : comment})
        .then((res) => {
          if(res.data.success){
            this.getUserOKR();
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Comment Deleted",
            //     icon: "BellIcon",
            //     variant: "danger",
            //     text: "Selected Comment Deleted Successfully",
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Comment Deleted',
                icon: 'BellIcon',
                variant: 'success',
                text: 'Selected Comment Deleted Successfully',
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
          }else{
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Error: Delete Failed",
            //     icon: "BellIcon",
            //     variant: "danger",
            //     text: "Unable to delete the selected",
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error: Delete Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: "Unable to delete the selected",
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });

    },
    addOrUpdateComment(objective_id,event,cmd_id = null){
      const cmd = event.target.value;
      if(cmd.length == 0){
        return;
      }
      axios
        .post("objective/addOrUpdateComment", {'comment' : cmd , 'objective_id' : objective_id , 'cmd_id' : cmd_id})
        .then(response => {
          if (response.data.success == true) {
            this.getUserOKR();
            event.target.value = '' ;
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Comment Updated",
            //     text: response.data.message,
            //     icon: "BellIcon",
            //     variant: "primary",
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Comment Updated',
                icon: 'BellIcon',
                variant: 'success',
                text: response.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
          } else {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Oops! Unable to fetch OKRs for Review",
            //     text: response.data.message,
            //     icon: "BellIcon",
            //     variant: "danger",
            //   },
            // });

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Unable to fetch OKRs for Review',
                icon: 'BellIcon',
                variant: 'danger',
                text: response.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
              
          }
        })
        .catch((error) => {
          console.log(error);
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Unable to fetch OKRs for Review",
          //     text: error.message,
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Unable to fetch OKRs for Review',
                icon: 'BellIcon',
                variant: 'danger',
                text: error.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    markAsResolved(objective_id,comment,state = 'resolved'){
      axios.post("objective/resolved",{objective_id:objective_id,comment : comment,targetState:state})
        .then((res) => {
          if(res.data.success){
            this.getUserOKR();
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Comment Addressed",
            //     icon: "BellIcon",
            //     variant: "danger",
            //     text: "Comment marked as resolved",
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Comment Addressed',
                icon: 'BellIcon',
                variant: 'success',
                text: "Comment marked as resolved",
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
              
          }else{
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Error: Marking Failed",
            //     icon: "BellIcon",
            //     variant: "danger",
            //     text: "Unable to mark the selected comment as rsolved",
            //   },
            // });

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error: Marking Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: '"Unable to mark the selected comment as resolved"',
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
             
          }
        })
        .catch((err) => {
          console.log(err);
        });

    },
    getDate(timestamp){
      const dateObj = new Date(timestamp);

      // Format date as DD/MM/YYYY
      const formattedDate = dateObj.toLocaleDateString("en-GB"); // "27/10/2024"
      return formattedDate;
    },
    getDateTime(timestamp){
      const dateObj = new Date(timestamp);

      // Format time as HH:MM AM/PM
      const formattedTime = dateObj.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true
      });
      return formattedTime;
    },
    openObjective(objective){
      alert(objective.hashid);
    },
    GetHistories(){
      var histories = [];
      for (let index = 0; index < this.objectives.length; index++) {
        const objective = this.objectives[index];
        if(objective.history.length){
          histories = histories.concat(objective.history);
        }        
        
      }
      return histories.reverse();
    },
    getStatusVariant(status) {
      if (status == 'Upcoming') {
        return 'light-info';
      } else if (status == 'Not Started Yet') {
        return 'light-notyet';
      } else if (status == 'In Progress') {
        return 'light-warning';
      } else if (status == 'Completed') {
        return 'light-primary';
      } else if (status == 'Cancelled') {
        return 'light-danger';
      } else if (status == 'On Hold') {
        return 'light-dark';
      } else if (status == 'Deferred') {
        return 'light-dark';
      }
      return 'light-warning';
    },
    getUserOKR() {
      axios
        .post("objectives/getUserOKRs", {
          user_id: this.user_id,
          quarter: this.quarter,
          year: this.year,
        })
        .then(response => {
          if (response.data.success == true) {
            this.objectives = response.data.data;
            if (response.data.data[0]) {
              this.name = response.data.data[0].user.name;
            }
          } else {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Oops! Unable to fetch OKRs for Review",
            //     text: response.data.message,
            //     icon: "BellIcon",
            //     variant: "danger",
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Unable to fetch OKRs for Review',
                icon: 'BellIcon',
                variant: 'danger',
                text: response.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });

          }
        })
        .catch((error) => {
          console.log(error);
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Unable to fetch OKRs for Review",
          //     text: error.message,
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Unable to fetch OKRs for Review',
                icon: 'BellIcon',
                variant: 'danger',
                text: error.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    approveAll() {
      let Ids = this.objectives.map(item => item.hashid);
      axios
        .post("objectives/approve", {
          user_id: this.user_id,
          quarter: this.quarter,
          year: this.year,
          objectiveIds: Ids,
        })
        .then(response => {
          if (response.data.success == true) {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Objectives Approved",
            //     text: "User OKRs are approved and published",
            //     icon: "BellIcon",
            //     variant: "primary",
            //   },
            // });

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Objectives Approved',
                icon: 'BellIcon',
                variant: 'success',
                text: "User OKRs are approved and published",
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });

            this.$router.push({ name: "review-okrs" });
          } else {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Oops! Unable to approve OKRs",
            //     text: response.data.message,
            //     icon: "BellIcon",
            //     variant: "danger",
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Unable to approve OKRs',
                icon: 'BellIcon',
                variant: 'danger',
                text: response.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Unable to sent notification",
          //     text: error.message,
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Unable to sent notification',
                icon: 'BellIcon',
                variant: 'danger',
                text: error.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    closeHistory() {
      this.$bvModal.hide("edithistory-pop");
    },
    notifyNeedImprovement() {
      axios
        .post("objectives/need-improvement", {
          user_id: this.user_id,
          quarter: this.quarter,
          year: this.year,
        })
        .then(response => {
          if (response.data.success == true) {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Notification Sent",
            //     text: "We informed the user that these objectives need improvements",
            //     icon: "BellIcon",
            //     variant: "primary",
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification Sent',
                icon: 'BellIcon',
                variant: 'success',
                text: "We informed the user that these objectives need improvements",
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
            this.$router.push({ name: "review-okrs" });
          } else {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Oops! Unable to sent notification",
            //     text: response.data.message,
            //     icon: "BellIcon",
            //     variant: "danger",
            //   },
            // });


            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Unable to sent notification',
                icon: 'BellIcon',
                variant: 'danger',
                text: response.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });

          }
        })
        .catch((error) => {
          console.log(error);
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Unable to sent notification",
          //     text: error.message,
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Unable to sent notification',
                icon: 'BellIcon',
                variant: 'danger',
                text: error.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    // OKR COMMENTS DELETE
    confirmDeleteRecord(data) {
      this.$swal({
        text: `Deleting this comment can not be undone. Are you sure you want to continue?`,
        showCancelButton: true,
        confirmButtonText: "Delete",
        customClass: {
          confirmButton: "btn yes-btn",
          cancelButton: "btn btn-outline-secondary ml-1 cancel-btn",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteRecord(data.hashid);
        }
      });
    },
    // END OKR COMMENTS DELETE
  },
};
</script>